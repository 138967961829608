<template>
  <div class="bg-grey-1">
    <Header :hideSearch="true" />
    <div class="mx-4 md:mx-44">
      <SearchBarNew class="hidden md:block" v-if="false" :filter="filter" @handleSearch="handleSearch"
        @handleWilayah="handleWilayah" />
        <div class="md:hidden flex flex-row gap-2" v-if="false">
          <div
            class="flex h-9 w-full items-center rounded-full overflow-hidden bg-white border border-e2 md:mx-5 my-3 md:my-0">
            <button
              class="flex justify-center items-center md:w-14 w-12 h-full bg-transparent hover:bg-e2 transition-all duration-500">
              <div class="flex justify-items-center">
                <svg class="md:w-8 md:h-8 w-6 h-6 text-black font-bold" fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3 30 30">
                  <path
                    d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z">
                  </path>
                </svg>
              </div>
            </button>
            <input placeholder="Search trip packages" maximum-scale="1"
              class="sm:px-2 py-2 w-full bg-transparent outline-none" name="search" autocomplete="off" type="text"
              v-model="filter.search" @keypress.enter="handleSearch" />
          </div>
          <div class="w-fit flex items-center">
            <button
              class="md:h-12 h-9 md:px-12 px-5 flex justify-center items-center rounded-full bg-primary text-white text-lg font-semibold hover:bg-primary-darker"
              @click="handleSearch">
              Search
            </button>
          </div>
        </div>
      <div class="flex gap-4 md:gap-10 md:my-8 mt-5">
        <FilterPaketNew ref="filterPaketNew" :listWilayah="listWilayah" :listSubMenu="listSubMenu" :filter="filter"
          @handleMenuChange="handleMenuChange" @changeFilter="handleFilter"
          @handleFilterSubmenu="handleFilterSubmenu" @handleSearch="handleSearch"
          @handleWilayah="handleWilayah"/>
        <div class="flex flex-col w-full">
          <div class="flex flex-col w-full justify-start md:flex-row md:justify-between items-center">
            <div class="font-bold text-base md:text-xl">
              Showing activities in {{ activeCountryName }}
            </div>
            <div class="flex flex-row justify-between md:justify-end items-center text-sm w-full md:w-2/5">
              <div class="flex flex-row md:hidden cursor-pointer items-center">
                <div class="flex flex-row items-end"><font-awesome-icon icon="fa-solid fa-filter" size="xl" class="text-gray-500" @click="openFilterModal" /> <span class="text-xs">Filter</span></div>
              </div>
              <div class="flex flex-row gap-3 items-center">
                <p>Short by:</p>
                <div class="px-5 py-1 rounded-full border border-grey-2 bg-white">
                  <select class="md:w-32 text-primary font-bold">
                    <option value="popular">Most Popular</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-6">
            <div v-for="paket in listPaket" :key="paket.id">
              <CardPaketNew :data="paket" :key="paket.id" class="cursor-pointer" @toggleWishlist="toggleWishlist"
                @selectPackage="selectPackage" />
            </div>
          </div>
          <div v-if="filter.idWilayah != '' && (listPaket.length == 0 || page.next == '' || page.next == null)"
            class="flex flex-col flex-grow justify-start items-center gap-4 my-8 md:my-0">
            <img src="../assets/no paket.png" alt="" class="h-24" v-if="listPaket.length == 0"/>
            <p class="text-center" v-if="listPaket.length == 0">No trip packages match your search {{ (filter.idWilayah != '') ? `in ${filter.idWilayah}` : '' }}</p>
            <p class="text-center" v-if="listPaket.length > 0 && (page.next == '' || page.next == null)">Not found what are you looking for ?</p>
            <p class="text-center" v-if="filter.idWilayah != ''">Try search in all region ? <span @click="searchAll" class="cursor-pointer text-blue-500 hover:text-blue-900">Click Here</span></p>
          </div>
          <div class="flex flex-row justify-between text-primary text-lg font-bold mt-2" v-if="listPaket.length > 0">
            <div>
              <span v-if="page.prev != '' && page.prev != null" class="cursor-pointer" @click="handlePaginate('prev')">
                <font-awesome-icon icon="fa-solid fa-arrow-left" />
                Prev Page
              </span>
            </div>
            <div>
              <span v-if="page.next != '' && page.next != null" class="cursor-pointer" @click="handlePaginate('next')">
                Next Page
                <font-awesome-icon icon="fa-solid fa-arrow-right" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import FilterPaketNew from "../components/FilterPaketNew.vue";
import SearchBarNew from "../components/SearchBarNew.vue";
import CardPaketNew from "../components/CardPaketNew.vue";
import DetailPaketHeroNew from "../components/DetailPaketHeroNew.vue";
import BookingDetailCardNew from "../components/BookingDetailCardNew.vue";
import { Slugify } from '@/utils';
export default {
  name: "ListPaket",
  components: {
    Header,
    Footer,
    FilterPaketNew,
    SearchBarNew,
    CardPaketNew,
    DetailPaketHeroNew,
    BookingDetailCardNew,
  },
  data() {
    return {
      filter: {
        trip_type: "safari",
        menu: "0", //0: all, 1: water, 2: land, 3 sky
        submenu: "",
        idWilayah: "",
        country: "ID",
        search: "",
        id_toko: "",
      },
      page: {
        prev: "",
        next: ""
      },
      listPaket: [],
      listSubMenu: [],
      listWilayah: [],
    };
  },
  async created() {
    if (this.$route.params.category == 'water') {
      this.filter.menu = '1';
    } else if (this.$route.params.category == 'land') {
      this.filter.menu = '2';
    } else if (this.$route.params.category == 'sky') {
      this.filter.menu = '3';
    } else {
      this.filter.menu = '0';
    }
    if (this.$route.params.id_toko != null) {
      this.filter.id_toko = this.$route.params.id_toko;
    } 
    if (this.$route.query.keyword != null) {
      this.filter.search = this.$route.query.keyword;
    } 

    await this.$nextTick();
    this.loadWilayah();
    await this.loadSubMenu();
    await this.loadPaket();
  },
  computed: {
    activeCountryName(){
      if(this.filter.country == 'MY'){
        return "Malaysia";
      }
      return "Indonesia";
    },
    queryUpdate(){
      return this.$route.query.keyword;
    }
  },
  watch: {
    queryUpdate(newVal, oldVal){
      this.filter.search = newVal;
      this.loadPaket();
    }
  },
  methods: {
    async openFilterModal() {
      this.$refs.filterPaketNew.open()
    },
    async handleFilter(e) {
      this.filter.idWilayah = e.nama_wilayah;
      await this.$nextTick();
      this.loadPaket();
    },
    async handleMenuChange(e) {
      this.filter.menu = e;
      this.filter.submenu = "";
      await this.$nextTick();
      this.listSubMenu = [];
      // await this.loadSubMenu();
      this.loadPaket();
    },
    async handleSearch() {
      await this.$nextTick();
      this.loadPaket();
    },
    async handleFilterSubmenu(e) {
      this.filter.submenu = e.menu_aktivitas;
      await this.$nextTick();
      this.loadPaket();
    },
    async handleWilayah() {
      await this.loadWilayah();
      await this.$nextTick();
    },
    async loadPaket(url = `${this.$apiTripweWeb}/package/list/all`) {
      // url = 'http://localhost:8888/api/v1/package/list/all';
      this.$store.commit("setOverlayLoading", true);
      try {
        const res = await this.$http.post(
          url,
          {
            id_wilayah: 0,
            nama_wilayah: this.filter.idWilayah,
            nama_paket: this.filter.search,
            submenu: this.filter.submenu,
            menu: this.filter.menu,
            id_toko: this.filter.id_toko,
          }
        );

        this.listPaket = res.data.data.item;
        this.page.prev = res.data.data.prev_page;
        this.page.next = res.data.data.next_page;
      } catch (error) {
        this.$toasted.global.error(
          "Fail to get data trips, please try again later"
        );
      } finally {
        this.$store.commit("setOverlayLoading", false);
        this.$refs.filterPaketNew.close()
      }
    },
    async loadSubMenu() {
      this.$store.commit("setOverlayLoading", true);
      try {
        const res = await this.$http.get(
          `${this.$apiTripweWeb}/category/submenu`, {

          params:
          {
            id_menu: this.filter.menu, //1: water, 2: land, 3: sky
          }
        }
        );

        this.listSubMenu = res.data.data;
        // if(this.listSubMenu.length > 0){
        //   this.filter.submenu = this.listSubMenu[0].menu_aktivitas
        // }
      } catch (error) {
        this.$toasted.global.error(
          "Fail to get data submenu, please try again later"
        );
      } finally {
        this.$store.commit("setOverlayLoading", false);
        this.$refs.filterPaketNew.close()
      }
    },
    async loadWilayah() {
      this.$store.commit("setOverlayLoading", true);
      try {
        const res = await this.$http.get(
          `${this.$apiTripweWeb}/wilayah/list`,
          {
            params: {
              "latitude": this.$store.state.currentLocation.latitude ?? '-6.1946352',
              "longitude": this.$store.state.currentLocation.longitude ?? '106.8230873',
              "order_col": "distance",
              "order_type": "",
              "country": this.filter.country
            }
          }
        );

        this.listWilayah = res.data.data;
        if(this.filter.country == "MY" && this.listWilayah.length > 0){
          this.handleFilter(this.listWilayah[0]);
        }else{
          // this.filter.idWilayah = "";
          this.handleFilter(this.listWilayah[0]);
          // await this.loadPaket();
        }
      } catch (error) {
        this.$toasted.global.error(
          "Fail to get data submenu, please try again later"
        );
      } finally {
        this.$store.commit("setOverlayLoading", false);
        this.$refs.filterPaketNew.close()
      }
    },
    selectPackage(pack) {
      if(pack.is_rental == 1){
        this.$router.push({
          name: 'Rental',
          params: { id_wilayah: pack.wilayah_toko, id_toko: pack.id_toko }
        });
      }else{
        this.$router.push({
          name: 'PackageDetail',
          params: { slug: pack.slug_url, pack }
        });
      }
    },
    handlePaginate(type) {
      if (type == 'next') {
        this.loadPaket(this.page.next);
      } else {
        this.loadPaket(this.page.prev);
      }
    },
    toggleWishlist(pack) {
      if (pack.idw != "0" && pack.idw != "" && pack.idw != null) {
        this.removeWishlist(pack);
      } else {
        this.addWishlist(pack);
      }
    },
    searchAll() {
      this.filter.idWilayah = "";
      this.loadPaket();
    },
    async addWishlist(pack) {
      this.$store.commit("setOverlayLoading", true);
      var datapost = {
        paket: pack.id,
        toko: pack.id_toko,
        jenis: parseInt(pack.tipe, 10) + 2,
      };

      var res = await this.$http.post(
        `${this.$apiTripweWeb}/wishlist/add`,
        datapost
      );
      if (res.data.status == 20000) {
        pack.idw = res.data.data.id.toString();
      }
      this.$store.commit("setOverlayLoading", false);
    },
    async removeWishlist(pack) {
      this.$store.commit("setOverlayLoading", true);
      var datapost = {
        id_wishlist: pack.idw,
      };

      var res = await this.$http.post(
        `${this.$apiTripweWeb}/wishlist/delete`,
        datapost
      );
      if (res.data.status == 20000) {
        pack.idw = "0";
      }
      this.$store.commit("setOverlayLoading", false);
    },
  },
};
</script>
